import 'bootstrap-autocomplete';
import naja from 'naja';

naja.initialize();

naja.uiHandler.addEventListener('interaction', (event) => {
    if($(event.detail.element).hasClass('ajax-append')){
        const snippet = document.getElementById('snippet--tripList');
        snippet.dataset.najaSnippetAppend = "";
    }
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    if (event.detail.snippet.id === 'snippet--tripList') {
        const snippet = document.getElementById('snippet--tripList');
        delete snippet.dataset.najaSnippetAppend;
    }
});

$(function(){
    $('.destination-autocomplete').autoComplete({
        minLength: 3,
        bootstrapVersion: '4',
    }).on('autocomplete.select', function(evt, item){
        $('input[name="destid"]').val(item.value);
    }).on('change', function(evt, item){
        if($(evt.target).val() == ""){
            $('input[name="destid"]').val("");
        }
    });
});
